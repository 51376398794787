import React from 'react';
import {
  useChangeQuantity,
  useIsSortPages,
  useMode,
  useQuantityByPageId,
  useRemovePage,
  useSortPage,
  useToggleOrientation,
  useUpdatePageId,
} from 'hooks';
import { usePageConfigContext } from 'context/pageConfig';
import {
  faMinusCircle,
  faPen,
  faPlusCircle,
  faRedoAlt,
  faTrash,
  faArrowUp,
  faArrowDown,
} from '@fortawesome/pro-regular-svg-icons';
import IconButton from 'components/common/IconButton/IconButton';
import { modes } from 'constants/index';
import { useSelector } from 'react-redux';
import { getPrintLimitMaxMinAndObjectsLengthSync } from 'components/common/PhotoLibrary/utils';
import c from '../AllPages.module.scss';

const PhotoActions = () => {
  const mode = useMode();
  const toggleOrientation = useToggleOrientation();
  const pageConfig = usePageConfigContext();
  const removePage = useRemovePage();
  const quantity = useQuantityByPageId();
  const updatePageId = useUpdatePageId();
  const isSortPages = useIsSortPages();
  const changeQuantity = useChangeQuantity();
  const onSort = useSortPage();
  const isShowRemoveIcon = useSelector((storeState) => {
    const { printLimitMin, photoCount } = getPrintLimitMaxMinAndObjectsLengthSync(storeState);
    if (mode !== modes.photoBook) return true;
    if (printLimitMin < photoCount) return true;
    return false;
  });
  return (
    <div className={c.photoActionsContainer}>
      {mode !== modes.photoBook ? (
        <div className={c.photoActions}>
          <IconButton icon={faMinusCircle} onClick={() => changeQuantity(-1)} />
          <div className="quantity">x{quantity}</div>
          <IconButton icon={faPlusCircle} onClick={() => changeQuantity(1)} />
        </div>
      ) : null}
      <div className={c.photoActions}>
        {mode !== modes.photoBook ? <IconButton onClick={toggleOrientation} icon={faRedoAlt} /> : null}
        <IconButton icon={faPen} onClick={() => updatePageId(pageConfig?.pageId)} />
        {isSortPages && mode === modes.photoBook && pageConfig.pageIndex > 1 ? (
          <IconButton onClick={() => onSort(pageConfig.pageIndex - 1)} icon={faArrowUp} />
        ) : null}
        {isSortPages && mode === modes.photoBook && pageConfig.pageIndex > 0 && !pageConfig.isLastImage ? (
          <IconButton onClick={() => onSort(pageConfig.pageIndex + 1)} icon={faArrowDown} />
        ) : null}
        {isShowRemoveIcon ? <IconButton onClick={removePage} icon={faTrash} /> : null}
      </div>
    </div>
  );
};

export default PhotoActions;
