// @ts-check
import {
  eachMonthOfInterval,
  startOfMonth,
  addMonths,
  getMonth,
  getYear,
  format,
  startOfYear,
  getDate,
} from 'date-fns';
import { min, max } from 'lodash';

/**
 * @typedef {Object} Point
 * @property {number} x
 * @property {number} y
 */

/**
 * @typedef {Object} Rect
 * @property {number} width
 * @property {number} height
 */

class CalculationShapeHelper {
  /**
   * Find the percentage of numbers
   * @param {number} length - base number (usually the size of a canvas)
   * @param {number} percent - percent (usually the size property of shape)
   * @returns {number}
   */
  pctToPx = (length, percent) => (percent * length) / 100;

  /**
   * What percentage is one number from another
   * @param {number} baseLength - base number (usually the size of a canvas)
   * @param {number} secondaryLength - secondary number (usually the size property of shape)
   * @returns {number}
   */
  pxToPct = (baseLength, secondaryLength) => (secondaryLength / baseLength) * 100;

  /**
   * position the shape in the center of the area (shape)
   * @param {import("../actions/scene.types").Shape} shape - the shape to be placed (only height and width values are used, other values are ignored)
   * @param {import("../actions/scene.types").Shape} area - the area (shape) in which you want to place the shape
   * @return {Point} x and y of the new shape
   */
  shapeToCenter(shape, area) {
    return { x: (area.x || 0) + (area.width - shape.width) / 2, y: (area.y || 0) + (area.height - shape.height) / 2 };
  }

  isShapeFullScreen(shape, dimensions) {
    if (
      shape.x === 0 &&
      shape.y === 0 &&
      shape.width === dimensions.width + 2 * dimensions.frame &&
      shape.height === dimensions.height + 2 * dimensions.frame
    ) {
      return true;
    }
    return false;
  }

  transformShapeFromPercent(shape, area) {
    return {
      ...shape,
      x: (area.x || 0) + this.pctToPx(area.width, shape.x * 100),
      y: (area.y || 0) + this.pctToPx(area.height, shape.y * 100),
      width: this.pctToPx(area.width, shape.width * 100),
      height: this.pctToPx(area.height, shape.height * 100),
    };
  }

  transformShapeToPercent(shape, area) {
    return {
      ...shape,
      x: this.pxToPct(area.width, shape.x) / 100,
      y: this.pxToPct(area.height, shape.y) / 100,
      width: this.pxToPct(area.width, shape.width) / 100,
      height: this.pxToPct(area.height, shape.height) / 100,
    };
  }

  transformImageToPercent(shape, image = shape.image) {
    return {
      x: this.pxToPct(shape.width, image.x),
      y: this.pxToPct(shape.width, image.y),
      width: this.pxToPct(shape.width, image.width),
      height: this.pxToPct(shape.width, image.height),
    };
  }

  /**
   * @param {import("../actions/scene.types").Shape} shape
   * @param {import("../actions/scene.types").ShapeImage} [image]
   * @returns {import("../actions/scene.types").ShapeImage}
   */
  transformImageFromPercent = (shape, image = shape.image) => ({
    ...image,
    x: this.pctToPx(shape.width, image?.x || 0),
    y: this.pctToPx(shape.width, image?.y || 0),
    height: this.pctToPx(shape.width, image?.height || 100),
    width: this.pctToPx(shape.width, image?.width || 100),
    offsetY: image?.offsetY ? this.pctToPx(shape.width, image.offsetY) : 0,
    offsetX: image?.offsetX ? this.pctToPx(shape.width, image.offsetX) : 0,
  });

  /**
   * rectangle1 (rect1) circumscribes a rectangle2 (rect2)
   * @param {import("../actions/scene.types").Shape} rect1
   * @param {import("../actions/scene.types").Shape} rect2
   * @return {import("../actions/scene.types").Shape} the rectangle1 circumscribed
   */
  rectCircumscribesRect(rect1, rect2) {
    const centerX = 0.5;
    const centerY = 0.5;
    const newShape = { x: 0, y: 0, width: rect1.width, height: rect1.height };
    const scale = Math.max(rect2.width / rect1.width, rect2.height / rect1.height);
    newShape.width *= scale;
    newShape.height *= scale;
    newShape.x = -(centerX * (newShape.width - rect2.width));
    newShape.y = -(centerY * (newShape.height - rect2.height));
    return newShape;
  }

  fitImageToShape(shape, image = shape.image, { usePercent = true } = {}) {
    let newImage = this.rectCircumscribesRect(image, shape);
    if (usePercent) newImage = this.transformImageToPercent(shape, newImage);
    newImage.centerX = 0.5;
    newImage.centerY = 0.5;
    newImage.minWidth = newImage.width;
    newImage.minHeight = newImage.height;
    newImage.offsetX = 0;
    newImage.offsetY = 0;
    return newImage;
  }

  formatDate(date) {
    return format(new Date(date.year, date.month), 'MMMM y');
  }

  addPlaceholders(text = '', options = {}) {
    const { date, eventList, listYears } = options;
    let renderText = text;
    if (date) {
      const startOfMonth = new Date(date?.year, date?.month);
      renderText = renderText
        .replace('{{year}}', format(startOfMonth, 'yyyy'))
        .replace('{{month}}', format(startOfMonth, 'MMMM'));
    } else if (listYears) {
      renderText = renderText.replace(
        '{{year}}',
        listYears.length > 1 ? `${min(listYears)}-${max(listYears)}` : `${min(listYears)}`,
      );
    }
    if (eventList) {
      renderText = renderText.replace(
        '{{events}}',
        eventList
          .map((event) => `<div>${event?.data?.originalCaption || `${event.day} ${event.event}`}</div>`)
          .join(''),
      );
    }
    return renderText;
  }

  getRangeCalendarFrom(startDate, countMonths = 11) {
    if (!startDate) {
      let currentDate = new Date();
      // if (getMonth(currentDate) >= 8) {
      //   currentDate = startOfYear(addYears(currentDate, 1));
      // }
      if (getMonth(currentDate) <= 4) {
        countMonths += getMonth(currentDate);
        currentDate = startOfYear(currentDate);
      }

      startDate = currentDate;
    }
    const currentMonth = startOfMonth(startDate);
    const interval = {
      start: currentMonth,
      end: addMonths(currentMonth, countMonths),
    };
    const range = eachMonthOfInterval(interval).map((date) => ({
      year: getYear(date),
      month: getMonth(date),
    }));
    return range;
  }

  getOptionsCalendarFrom() {
    const optionsCalendarFrom = this.getRangeCalendarFrom().map((date) => ({
      label: this.formatDate(date),
      value: date,
    }));
    return optionsCalendarFrom;
  }

  generateOptionsFromConstants(array) {
    return array.map((value) => ({ value, label: value.toString() }));
  }

  /**
   * converts Date type to object { month, day }
   * @param {Date|any} date
   * @returns object { month, day } or if date not Date type return date
   */
  dateToDayMonthObj(date) {
    return date instanceof Date ? { day: getDate(date), month: getMonth(date) } : date;
  }

  /**
   * move an array element from one array position to another
   * @param {any[]} arr
   * @param {number} fromIndex
   * @param {number} toIndex
   * @returns {any[]}
   */
  arrayMove(arr, fromIndex, toIndex) {
    const newArray = [...arr];
    const element = newArray[fromIndex];
    newArray.splice(fromIndex, 1);
    newArray.splice(toIndex, 0, element);
    return newArray;
  }
}

export default new CalculationShapeHelper();
