// @ts-check

import React, { useMemo } from 'react';
import format from 'date-fns/format';
import { getCalendarSettings } from 'constants/calendarSettings';

/**
/**
 * The WeekdayName component renders the name of a weekday.
 *
 * @param {Object} props The props for the component.
 * @param {import('actions/scene.types').Shape} props.shape The shape of the calendar.
 * @param {Date} props.day The date
 * @param {number} props.index The index of the weekday.
 *
 * @returns {JSX.Element} The rendered day component.
 */
const WeekdayName = ({ shape, day, index }) => {
  const calendarSettings = useMemo(() => getCalendarSettings(shape.calendarType), [shape.calendarType]);
  const dayOfWeekFormatting = format(day, shape.dayFormat || 'EEEEEE');
  const nameTextProps = useMemo(() => {
    let textAlign = 'end';
    if (shape.calendarType === 2) {
      textAlign = 'middle';
    }
    if (textAlign === 'end') {
      return {
        x: calendarSettings.DayShape.width - 2 * calendarSettings.DayShape.margin,
        textAnchor: 'end',
      };
    }
    if (textAlign === 'start') {
      return {
        x: calendarSettings.DayShape.margin,
        textAnchor: 'start',
      };
    }
    return {
      x: calendarSettings.DayShape.width / 2,
      textAnchor: 'middle',
    };
  }, [calendarSettings.DayShape.margin, calendarSettings.DayShape.width, shape.calendarType]);
  return (
    <g transform={`translate(${index * calendarSettings.DayShape.width} 0)`}>
      <text
        {...nameTextProps}
        y={calendarSettings.DayShape.height - calendarSettings.DayShape.margin}
        fontSize={shape.headerFontSize}
        dominantBaseline="text-top"
        fontWeight={shape.headerIsBold ? 'bold' : 'normal'}
        fontStyle={shape.headerIsItalic ? 'italic' : 'normal'}
        fontFamily={shape.headerFontFamily || shape.fontFamily}
        fill={shape.headerColor || shape.mainColor}
      >
        {dayOfWeekFormatting}
      </text>
    </g>
  );
};

export default WeekdayName;
