import React from 'react';
import { useAxisOptions, useCurrentPageId, useEditorSizePx, useIsEditing, useShowArrow, useViewAllPages } from 'hooks';
import { PageConfigContextProvider } from 'context/pageConfig';
import useUpdateIsEditing from 'hooks/useUpdateIsEditing';
import ArrowMemo from './components/Arrow';
import Editor from './components/Editor';
import useIsCrop from './hooks/useIsCrop';
import CropPhotoContainer from './components/CropPhotoComponent';
import AllPages from './components/AllPages';
import c from './EditorContainer.module.scss';

const EditorOnePage = () => {
  const currentPageId = useCurrentPageId();
  const [width, height] = useEditorSizePx(currentPageId);
  const isEditing = useIsEditing();
  const setIsEditing = useUpdateIsEditing();
  const showArrow = useShowArrow();
  const { xLabel, yLabel, axisColor } = useAxisOptions();
  return (
    <div className={c.containerNewEditor} onClick={() => isEditing && setIsEditing(false)}>
      <div className="newEditor" style={{ margin: 'auto' }}>
        <div style={{ display: 'flex' }}>
          {showArrow ? <ArrowMemo vertical width={height / 3} text={yLabel} color={axisColor} /> : null}
          <PageConfigContextProvider value={{ pageId: currentPageId, editing: true }}>
            {/* <TransformComponent key={currentPageId} wrapperClass={c.transformComponent}> */}
            <Editor />
            {/* </TransformComponent> */}
          </PageConfigContextProvider>
        </div>
        {showArrow ? <ArrowMemo width={width / 3} text={xLabel} color={axisColor} /> : null}
      </div>
    </div>
  );
};

const EditorContainer = () => {
  const [isCrop] = useIsCrop();
  const viewAllPages = useViewAllPages();
  if (isCrop) {
    return <CropPhotoContainer />;
  }
  if (viewAllPages) {
    return <AllPages />;
  }
  return <EditorOnePage />;
};

export default EditorContainer;
