import React, { useState, useMemo } from 'react';
import { weekStartingConst } from 'constants/index';
import Button from 'components/common/Button';
import { find } from 'lodash';
import CalculationShapeHelper from 'utils/CalculationShapeHelper';
import { ReactComponent as HeaderImage } from 'assets/calendar-modal-header-icon.svg';
import headerBgSrc from 'assets/calendar-settings-modal-bg.png';
import classNames from 'classnames';
import store from 'store';
import Select from 'components/form/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import FormField from 'components/form/FormField';
import SceneHelper from 'utils/SceneHelper';
import WeekStartingInput from './WeekStartingInput';
import c from './SetConfigurationCalendars.module.scss';

const serializeFromOptionValue = (value) => {
  return `${value.month}_${value.year}`;
};
const deserializeFromOptionValue = (string) => {
  const [month, year] = string.split('_');
  return { month, year };
};
const getDefaultStartFrom = () => {
  const optionsCalendarFrom = CalculationShapeHelper.getOptionsCalendarFrom();
  const storeState = store.getState();
  const startFrom = SceneHelper.getStartFrom(storeState);
  if (!startFrom) {
    return optionsCalendarFrom[0].value.month > 8
      ? find(optionsCalendarFrom, { value: { month: 0 } }).value
      : optionsCalendarFrom[0].value;
  }
  return startFrom;
};
const getDefaultWeekStarting = () => {
  const storeState = store.getState();
  const weekStarting = SceneHelper.getWeekStarting(storeState);
  return weekStarting || weekStartingConst.monday;
};

const SetConfigurationCalendars = ({ onConfirm, confirmText = 'Confirm & Save' }) => {
  const optionsCalendarFromSerialized = useMemo(() => {
    const optionsCalendarFrom = CalculationShapeHelper.getOptionsCalendarFrom();
    return optionsCalendarFrom.map(({ value, ...rest }) => ({
      ...rest,
      value: serializeFromOptionValue(value),
    }));
  }, []);

  const [startFrom, setStartFrom] = useState(getDefaultStartFrom());
  const [weekStarting, setWeekStarting] = useState(getDefaultWeekStarting());

  return (
    <>
      <div className={classNames('modal-header', c.headerContainer)}>
        <div className={c.header}>
          <img src={headerBgSrc} className={c.headerBg} alt="" />
          {/* <HeaderBg className={c.headerBg} /> */}
          <HeaderImage className={c.headerImg} />
        </div>
        <div className="modal-header-text">Starting month & day</div>
        <div className="subtitle">
          What <b>month and day</b> would you like your calendar to start?
        </div>
      </div>
      <div className="modal-body">
        <FormField>
          <Select
            value={serializeFromOptionValue(startFrom)}
            options={optionsCalendarFromSerialized}
            onChange={(str) => {
              setStartFrom(deserializeFromOptionValue(str));
            }}
            icon={<FontAwesomeIcon.Memo icon={faCalendar} />}
          />
        </FormField>
        <WeekStartingInput value={weekStarting} onChange={setWeekStarting} />
      </div>
      <div className="action-buttons-vertical">
        <Button color="azure" rounded size="lg" onClick={() => onConfirm({ startFrom, weekStarting })}>
          {confirmText}
        </Button>
      </div>
    </>
  );
};

export default SetConfigurationCalendars;
