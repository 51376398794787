import React from 'react';
import { faFont, faBold, faItalic } from '@fortawesome/pro-regular-svg-icons';
import { faBold as faBoldSelect, faItalic as faItalicSelect } from '@fortawesome/pro-solid-svg-icons';
import { useUpdateShape } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToolBarColorPicker from '../../components/ToolbarColorPicker';
import FontFamilySelect from '../../components/FontFamilySelect';
import SelectFontSize from '../../components/SelectFontSize';
import Collapse from './Collapse';

export const DayStyle = ({ object, toolbarRef }) => {
  const onChange = useUpdateShape(object.id);
  return (
    <>
      <Collapse text="Day">
        <>
          <SelectFontSize value={object.dayFontSize} onChange={(value) => onChange({ dayFontSize: value })} />
          <FontFamilySelect initialValue={object.fontFamily} onChange={(value) => onChange({ fontFamily: value })} />
          <div className="icon-button-container" onClick={() => onChange({ isBold: !object.isBold })}>
            <FontAwesomeIcon.Memo icon={object.isBold ? faBoldSelect : faBold} className="icon-button" />
          </div>
          <div className="icon-button-container" onClick={() => onChange({ isItalic: !object.isItalic })}>
            <FontAwesomeIcon.Memo icon={object.isItalic ? faItalicSelect : faItalic} className="icon-button" />
          </div>
          <ToolBarColorPicker
            initialValue={object.mainColor}
            toolbarRef={toolbarRef}
            onChange={(value) => onChange({ mainColor: value })}
            icon={faFont}
          />
        </>
      </Collapse>
    </>
  );
};

export default DayStyle;
