import React from 'react';
import { useSelector } from 'react-redux';
import { layoutGutterTypeOptions } from 'constants/editor';
import VerticalSelect from 'components/common/VerticalSelect';
import { modes } from 'constants/index';
import { includes } from 'lodash';
import { useChangeLayoutGutterType, useLayoutGutterType } from 'hooks';
import { useMode, useViewAllPages } from 'hooks/sceneHooks';

const LayoutGutterTypeSelect = () => {
  const layoutGutterType = useLayoutGutterType();
  const changeLayoutGutterType = useChangeLayoutGutterType();
  const mode = useMode();
  const showLayoutGutterSwitch = useSelector(
    ({
      scene: {
        config: { showLayoutGutterSwitch },
      },
    }) => showLayoutGutterSwitch,
  );
  const viewAllPages = useViewAllPages();
  if (mode === modes.photoBook && !viewAllPages) {
    return null;
  }
  if (!showLayoutGutterSwitch || !includes([modes.calendar, modes.poster, modes.mask, modes.photoBook], mode))
    return null;
  return (
    <div>
      <VerticalSelect
        options={layoutGutterTypeOptions}
        onChange={changeLayoutGutterType}
        value={layoutGutterType}
        onlyInnerDelimiters
        preservePaddingForCheck
      />
    </div>
  );
};

export default LayoutGutterTypeSelect;
