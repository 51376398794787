import React, { useRef } from 'react';
import { faFont, faFillDrip, faBorderOuter, faBold, faItalic, faBorderTop } from '@fortawesome/pro-regular-svg-icons';
import {
  faBold as faBoldSelect,
  faItalic as faItalicSelect,
  faBorderTop as faBorderTopSelect,
} from '@fortawesome/pro-solid-svg-icons';
import { useAdmin, useUpdateShape } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToolBarColorPicker from '../components/ToolbarColorPicker';
import FontFamilySelect from '../components/FontFamilySelect';
import ActionsContainer from '../components/ActionsContainer';
import VerticalDivider from '../components/VerticalDivider';
import './ActionBarCalendar.scss';
import SelectFontSize from '../components/SelectFontSize';
import SelectFormatDate from '../components/SelectFormatDate';
import DayStyle from './components/DayStyle';
import EventStyle from './components/EventStyle';
import CustomSelect from '../components/CustomSelect';

export const CalendarFooter = ({ hide, shape }) => {
  const onChange = useUpdateShape(shape.id);
  const admin = useAdmin();
  const toolbarRef = useRef();

  return (
    <ActionsContainer hide={hide} maxWidth={admin ? 740 : undefined}>
      <DayStyle object={shape} toolbarRef={toolbarRef} />
      <VerticalDivider />

      <EventStyle object={shape} toolbarRef={toolbarRef} />
      <VerticalDivider />

      <SelectFormatDate
        value={shape.dayFormat}
        onChange={(dayFormat) => {
          onChange({ dayFormat });
        }}
      />
      <SelectFontSize value={shape.headerFontSize} onChange={(value) => onChange({ headerFontSize: value })} />
      <FontFamilySelect
        initialValue={shape.headerFontFamily}
        onChange={(value) => onChange({ headerFontFamily: value })}
      />
      <div className="icon-button-container" onClick={() => onChange({ headerIsBold: !shape.headerIsBold })}>
        <FontAwesomeIcon.Memo icon={shape.headerIsBold ? faBoldSelect : faBold} className="icon-button" />
      </div>
      <div className="icon-button-container" onClick={() => onChange({ headerIsItalic: !shape.headerIsItalic })}>
        <FontAwesomeIcon.Memo icon={shape.headerIsItalic ? faItalicSelect : faItalic} className="icon-button" />
      </div>
      <ToolBarColorPicker
        initialValue={shape.headerColor}
        toolbarRef={toolbarRef}
        onChange={(value) => onChange({ headerColor: value })}
        icon={faFont}
      />

      {admin ? (
        <>
          <div>
            x{' '}
            <input
              className="number-input"
              type="number"
              onChange={(e) => onChange({ x: parseFloat(e.target.value) || 0 })}
              value={shape.x}
            />
          </div>
          <div>
            y{' '}
            <input
              className="number-input"
              type="number"
              onChange={(e) => onChange({ y: parseFloat(e.target.value) || 0 })}
              value={shape.y}
            />
          </div>
          <div>
            H{' '}
            <input
              className="number-input"
              type="number"
              onChange={(e) => onChange({ height: parseFloat(e.target.value) })}
              value={shape.height}
            />
          </div>
          <div>
            W{' '}
            <input
              className="number-input"
              type="number"
              onChange={(e) => onChange({ width: parseFloat(e.target.value) })}
              value={shape.width}
            />
          </div>
          <VerticalDivider />
        </>
      ) : null}

      <div className="icon-button-container" onClick={() => onChange({ borderTop: !shape.borderTop })}>
        <FontAwesomeIcon.Memo icon={shape.borderTop ? faBorderTopSelect : faBorderTop} className="icon-button" />
      </div>

      <ToolBarColorPicker
        initialValue={shape.borderColor}
        toolbarRef={toolbarRef}
        onChange={(value) => onChange({ borderColor: value })}
        icon={faBorderOuter}
      />
      <ToolBarColorPicker
        initialValue={shape.secondaryColor}
        toolbarRef={toolbarRef}
        onChange={(value) => onChange({ secondaryColor: value })}
        icon={faBorderOuter}
      />

      <VerticalDivider />

      <CustomSelect
        options={[
          {
            value: 1,
            label: '1',
          },
          {
            value: 2,
            label: '2',
          },
        ]}
        value={shape.calendarType}
        onChange={(calendarType) => {
          onChange({ calendarType });
        }}
      />

      <ToolBarColorPicker
        initialValue={shape.backgroundColor}
        toolbarRef={toolbarRef}
        onChange={(value) => onChange({ backgroundColor: value })}
        icon={faFillDrip}
        disableAlpha={false}
        disableIconColorMatching
      />
    </ActionsContainer>
  );
};

export default CalendarFooter;
