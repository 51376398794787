// @ts-check

import React, { useMemo } from 'react';
import TextInSvg from 'utils/svg-export/components/SVGPage/components/TextInSvg';
import format from 'date-fns/format';
import getMonth from 'date-fns/getMonth';
import { useCurrentPageDate } from 'hooks';
import find from 'lodash/find';
import { getCalendarSettings } from 'constants/calendarSettings';

/**
 * The Day component renders a day of the week.
 *
 * @param {Object} props The props for the component.
 * @param {string} props.name The name of the day.
 * @param {number} props.x The x-coordinate of the day.
 * @param {number} props.y The y-coordinate of the day.
 * @param {string} props.color The color of the day.
 * @param {string} props.fontFamily The font family of the day.
 * @param {string} props.borderColor The border color of the day.
 * @param {string} props.fontWeight The font weight of the day.
 * @param {string} props.fontStyle The font style of the day.
 * @param {number} props.fontSize The font size of the day.
 * @param {boolean} props.borderTop Whether to show a top border on the day.
 * @param {Array} props.dayShapes An array of shapes to draw on the day.
 * @param {Object} props.calendarSettings An array of shapes to draw on the day.
 * @param {'start' | 'end' | 'middle'} props.textAlign The text alignment of the day.
 *
 * @returns {JSX.Element} The rendered day component.
 */
const Day = ({
  name,
  x,
  y,
  color,
  fontFamily,
  borderColor,
  fontWeight,
  fontStyle,
  fontSize,
  borderTop,
  dayShapes,
  calendarSettings,
  textAlign = 'end',
}) => {
  const dayTextProps = useMemo(() => {
    if (textAlign === 'end') {
      return {
        x: calendarSettings.DayShape.width - 2 * calendarSettings.DayShape.margin,
        textAnchor: 'end',
      };
    }
    if (textAlign === 'start') {
      return {
        x: calendarSettings.DayShape.margin,
        textAnchor: 'start',
      };
    }
    return {
      x: calendarSettings.DayShape.width / 2,
      textAnchor: 'middle',
    };
  }, [calendarSettings.DayShape.margin, calendarSettings.DayShape.width, textAlign]);
  return (
    <g transform={`translate(${x} ${y})`}>
      {borderTop ? (
        <line
          x1={calendarSettings.DayShape.margin}
          x2={calendarSettings.DayShape.width - 2 * calendarSettings.DayShape.margin}
          y1="0"
          y2="0"
          stroke={borderColor}
          strokeWidth="1"
        />
      ) : null}
      <text
        {...dayTextProps}
        y={calendarSettings.DayShape.margin}
        fontSize={fontSize}
        fontFamily={fontFamily}
        dominantBaseline="hanging"
        fill={color}
        fontWeight={fontWeight}
        fontStyle={fontStyle}
      >
        {name}
      </text>
      {dayShapes?.map((shape) => {
        return <TextInSvg shape={{ fill: color, ...shape }} />;
      })}
    </g>
  );
};

/**
 * The DayContainer component renders a container for a day.
 *
 * @param {Object} props The props for the component.
 * @param {number} props.index The index of the day.
 * @param {Date} props.day The name of the day.
 * @param {import('actions/scene.types').Shape} props.shape The shape of the calendar.
 *
 * @returns {JSX.Element} The rendered day container component.
 */
const DayContainer = ({ index, day, shape }) => {
  const textAlign = useMemo(() => {
    if (shape.calendarType === 2) {
      return 'middle';
    }
    return 'end';
  }, [shape.calendarType]);
  const calendarSettings = useMemo(() => getCalendarSettings(shape.calendarType), [shape.calendarType]);
  const currentDate = useCurrentPageDate();
  const dayOfWeek = useMemo(
    () => index % calendarSettings.NumberDaysInRowAndColumn.column,
    [calendarSettings.NumberDaysInRowAndColumn.column, index],
  );
  const isCurrentMonth = useMemo(() => getMonth(day) === currentDate?.month, [currentDate?.month, day]);
  const x = useMemo(() => {
    return (index % calendarSettings.NumberDaysInRowAndColumn.column) * calendarSettings.DayShape.width;
  }, [calendarSettings.DayShape.width, calendarSettings.NumberDaysInRowAndColumn.column, index]);
  const y = useMemo(() => {
    const numberOfWeek = Math.floor(index / calendarSettings.NumberDaysInRowAndColumn.column);
    return numberOfWeek * calendarSettings.DayShape.height;
  }, [calendarSettings.DayShape.height, calendarSettings.NumberDaysInRowAndColumn.column, index]);
  const name = format(day, 'd');
  const color = isCurrentMonth ? shape.mainColor : shape.secondaryColor;
  const isShow = useMemo(() => {
    if (isCurrentMonth) return true;
    if (dayOfWeek - parseInt(format(day, 'd'), 10) < 0 && parseInt(format(day, 'd'), 10) < 14) return false;
    return true;
  }, [day, dayOfWeek, isCurrentMonth]);
  const borderColor = getMonth(day) === currentDate?.month ? shape.borderColor || color : shape.secondaryColor;
  const fontWeight = shape.isBold ? 'bold' : 'normal';
  const fontStyle = shape.isItalic ? 'italic' : 'normal';
  const dayShapes = find(shape.days, { day: format(day, 'dd.MM.yyyy') })?.shapes;
  if (!isShow) return null;
  return (
    <Day
      x={x}
      y={y}
      name={name}
      color={color}
      borderTop={shape.borderTop}
      fontSize={shape.dayFontSize}
      borderColor={borderColor}
      fontFamily={shape.fontFamily}
      fontWeight={fontWeight}
      fontStyle={fontStyle}
      dayShapes={dayShapes}
      textAlign={textAlign}
      calendarSettings={calendarSettings}
    />
  );
};

export default DayContainer;
