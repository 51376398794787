import React, { useMemo } from 'react';
import Button from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import pluralize from 'pluralize';
import { every } from 'lodash';
import { update, updateModalProps } from 'actions/modal';
import store from 'store';
import PixaPrints from 'api';
import { getPrintLimitMaxMinAndObjectsLengthSync } from 'components/common/PhotoLibrary/utils';
import SceneHelper from 'utils/SceneHelper';
import { isAllImageLoaded, openModal } from 'utils/utils';
import { modalTypes, modes } from 'constants/index';
import {
  useAdmin,
  useCountPagesWithoutImages,
  useIsOneImageLoaded,
  useIsOneImageLoading,
  useIsSortPages,
  useMode,
  useViewAllPages,
} from 'hooks';

const initialResponseStatus = { status: null, message: null };
const handleOrder = async () => {
  store.dispatch(update({ type: 'loading', modalProps: initialResponseStatus }));
  try {
    await PixaPrints.orderItem();
    store.dispatch(updateModalProps({ success: true }));
  } catch (e) {
    store.dispatch(
      updateModalProps({
        success: false,
        message: e.fakeMessage || 'Error while ordering',
        cancellable: e.dismissible,
      }),
    );
  }
};

const openOrderModal = ({ warnings = [] }) =>
  openModal({ type: 'order', modalProps: { onConfirm: handleOrder, warnings } });
const openMorePhotosNeededModal = (printLimitMin) =>
  openModal({
    type: modalTypes.warning,
    modalProps: { message: `Minimum of ${printLimitMin} photos needed` },
  });
const openPhotosLoadingModal = () =>
  openModal({
    type: modalTypes.warning,
    modalProps: { message: 'Please wait until your photos are uploaded' },
  });
const isAllPhotosLoaded = () =>
  every(store.getState().photos.photos, 'uploaded') ? true : !!(openPhotosLoadingModal() && false);

const onClickOrder = () => {
  const { scene, photos } = store.getState();
  const { printLimitMin, photoCount } = getPrintLimitMaxMinAndObjectsLengthSync({ scene });
  if (printLimitMin > photoCount && scene.config.mode === modes.photoPrint)
    return openMorePhotosNeededModal(printLimitMin);
  if (!isAllPhotosLoaded()) return null;
  const warnings = [];
  if (!isAllImageLoaded(scene)) warnings.push('Some boxes are missing photos.');
  const lowQualityImage = SceneHelper.countLowQualityImage({ scene, photos });
  if (lowQualityImage) warnings.push(`You have ${pluralize('photo', lowQualityImage, true)} that is low resolution`);
  return openOrderModal({ warnings });
};

const useIsShowOrderButton = () => {
  const mode = useMode();
  const admin = useAdmin();
  const isSortPages = useIsSortPages();
  const viewAllPages = useViewAllPages();
  const isShow = useMemo(() => {
    return (
      !admin &&
      ((viewAllPages && !isSortPages) || mode === modes.canvas || mode === modes.mask || mode === modes.poster)
    );
  }, [admin, isSortPages, mode, viewAllPages]);
  return isShow;
};

const OrderButton = () => {
  const isOneImageLoaded = useIsOneImageLoaded();
  const countPagesWithoutImages = useCountPagesWithoutImages();
  const isOneImageLoading = useIsOneImageLoading();
  const mode = useMode();
  const isShow = useIsShowOrderButton();
  if (!isShow) return null;
  return (
    <Button
      onClick={onClickOrder}
      disabled={isOneImageLoading || !isOneImageLoaded || (mode === modes.photoBook && countPagesWithoutImages)}
      className="nav-bar-button"
      type="button"
      size="lg"
      textSize="md"
      rounded
      color="azure"
    >
      <span>Continue</span>
      <FontAwesomeIcon.Memo icon={faArrowRight} />
    </Button>
  );
};

export default OrderButton;
