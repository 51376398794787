import he from 'he';
import { inchesToMm } from 'utils/number';
import exportToSvg from 'utils/svg-export';
import { find, first, merge, get, flatten } from 'lodash';
import { changeLayoutId, updateConfig } from 'actions/scene';
import store from 'store';
import { close } from 'actions/modal';
import { layoutModes, defaultPageCoordinates, modalTypes, modes } from 'constants/index';
import { defaultColors } from 'api/colors';
import { openModal } from 'utils/utils';
import StoreSceneHelper from '../utils/SceneHelper';
import { layouts, fullScreenLayout } from './layouts';

const selectLayouts = (enableMultiLayout, layoutMode) => {
  if (layoutMode) {
    if (layoutMode === layoutModes.singleOnly) {
      return fullScreenLayout;
    }
    if (layoutMode === layoutModes.multiOnly) {
      return layouts;
    }
    return [...fullScreenLayout, ...layouts];
  }
  if (enableMultiLayout) {
    return layouts;
  }
  return fullScreenLayout;
};

const transformPages = (pages) => {
  if (pages) {
    return pages.map((page) => {
      const _dimensions = page?.config?.dimensions;
      const dimensions = _dimensions && {
        ..._dimensions,
        width: inchesToMm(_dimensions.width),
        height: inchesToMm(_dimensions.height),
      };
      return merge({}, page, { config: { dimensions } });
    });
  }
  return null;
};

export const processSizes = (sizes, enableMultiLayout, layoutMode, defaultBackground, backgroundOptions) =>
  sizes.map((size) => {
    let height = inchesToMm(size.value.dimensions.height);
    let width = inchesToMm(size.value.dimensions.width);
    const pages = transformPages(size.value?.pages);
    const pageCoordinates = get(size, 'value.pageCoordinates') || defaultPageCoordinates;
    let { xLabel, yLabel } = size.value;
    const widthLessThanHeight = width < height; // width is always wider than height
    if (widthLessThanHeight) {
      [width, height] = [height, width];
      [xLabel, yLabel] = [yLabel, xLabel];
    }
    return {
      ...size,
      description: he.decode(size.description),
      value: {
        ...size.value,
        dimensions: {
          width,
          height,
        },
        pages,
        pageCoordinates,
        layouts: selectLayouts(enableMultiLayout, layoutMode),
        xLabel,
        yLabel,
        overlay3dUrl: size.value.overlay3dUrl || '',
        scaleOverlay: size.value.scaleOverlay || 1,
        scaleOverlay3d: size.value.scaleOverlay3d || 1,
        backgroundOptions: backgroundOptions || defaultColors,
        currentBackground:
          (defaultBackground && { value: defaultBackground }) ||
          find(defaultColors, { default: true }) ||
          first(defaultColors),
      },
    };
  });

const downloadS = (scene, download, options) => {
  const svgString = exportToSvg(scene, options);
  if (download === 'string') {
    return svgString;
  }

  const blob = new Blob([svgString], { type: 'image/svg+xml' });
  const url = (window.URL || window.webkitURL).createObjectURL(blob);

  if (download) {
    const a = document.createElement('a');
    a.download = `${new Date().getTime()}.svg`;
    a.type = 'image/svg+xml';
    a.href = url;
    a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
  } else {
    window.open(url, '_blank');
  }
  return null;
};

export const downloadSvg = (scene, download, options) => {
  const { renderMask = false, onlyCurrentPage = false } = options || {};
  if (onlyCurrentPage) {
    const pageId = StoreSceneHelper.currentPageId({ scene });
    const pageCoordinates = StoreSceneHelper.getPageCoordinates({ scene }, pageId);
    return pageCoordinates.map((coordinates) => downloadS(scene, download, { renderMask, coordinates, pageId }));
  }
  const { pages } = scene;
  return flatten(
    pages.map((page) => {
      const pageCoordinates = StoreSceneHelper.getPageCoordinates({ scene }, page.id);
      return pageCoordinates.map((coordinates) =>
        downloadS(scene, download, { renderMask, pageId: page.id, coordinates }),
      );
    }),
  );
};

/**
 * set layout to scene
 * @param {string} layoutId - layout id
 * @param {LayoutType[]} layouts - layouts
 * @param {string} mode - layouts
 */
export const setLayoutId = (layoutId, layouts, mode) => {
  const currentLayout = find(layouts, { id: layoutId });
  if (currentLayout) {
    const countLayoutApertures = currentLayout.layout.length;
    if (countLayoutApertures === 1 && mode === modes.canvas) {
      const changeLayoutIdContainer =
        ({ withFrame }) =>
        () => {
          store.dispatch(changeLayoutId({ id: currentLayout.id, withFrame }));
          store.dispatch(close());
        };
      openModal({
        type: modalTypes.confirm,
        modalProps: {
          onConfirm: changeLayoutIdContainer({ withFrame: false }),
          onClose: changeLayoutIdContainer({ withFrame: true }),
          header: 'Add layout',
          text: 'Please select where to place the layout',
          confirmText: 'Colour edges',
          cancelText: 'Image on edges',
        },
      });
    } else {
      store.dispatch(changeLayoutId({ id: currentLayout.id, withFrame: false }));
    }
  } else console.warn('unknown size option with id ', layoutId);
};

export const smartSetLayoutId = (layoutId) => {
  const {
    scene: {
      config: { layouts, mode },
    },
  } = store.getState();
  setLayoutId(layoutId, layouts, mode);
};

export const setCurrentBackground = (value) => {
  const {
    scene: {
      config: { backgroundOptions },
    },
  } = store.getState();
  const newValues = find(backgroundOptions, { value });
  newValues && store.dispatch(updateConfig({ currentBackground: newValues }));
};
