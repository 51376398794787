import React, { useCallback } from 'react';
import { faBold, faItalic, faAlignLeft, faAlignRight, faFont } from '@fortawesome/pro-regular-svg-icons';
import { faAlignCenter, faBold as faBoldSelect, faItalic as faItalicSelect } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ActionBarText.scss';
import textAligns from 'constants/textAligns';
import MultiOptionButton from 'components/common/MultiOptionButton';
import { useNormalizeObject } from 'utils/utils';
import { useUpdateShapeDebounced } from 'hooks';
import useIsMobile from 'hooks/useIsMobile';
import useTextIsEditing from 'components/editors/NewEditor/hooks/useTextEditing';
import ToolBarColorPicker from '../components/ToolbarColorPicker';
import FontFamilySelect from '../components/FontFamilySelect';
import ShapeLock from '../components/ShapeLock';
import ShapeDelete from '../components/ShapeDelete';
import ActionsContainer from '../components/ActionsContainer';
import SelectFontSize from '../components/SelectFontSize';
import DoneButton from '../components/DoneButton';
import OrderObjects from '../components/OrderObjects';

const CustomAlignLabel = (props) => {
  const { optionValue } = props;
  let icon = faAlignLeft;
  if (optionValue === 'center') icon = faAlignCenter;
  if (optionValue === 'right') icon = faAlignRight;
  return <FontAwesomeIcon.Memo icon={icon} style={{ height: '1.22em', width: '1.22em', marginRight: '0px' }} />;
};

export const ActionBarText = ({ hide, shape }) => {
  const onChange = useUpdateShapeDebounced(shape.id);
  const normalizeObject = useNormalizeObject();
  const [textEditing] = useTextIsEditing();
  const mobile = useIsMobile();
  const changeText = useCallback(
    (props) => {
      const newTextProps = normalizeObject({ ...shape, ...props });
      if (newTextProps) {
        onChange({ ...props, ...newTextProps });
      }
    },
    [normalizeObject, shape, onChange],
  );
  if (textEditing && mobile) {
    return (
      <div className="editTextNavbar">
        <DoneButton />
      </div>
    );
  }

  return (
    <>
      <ActionsContainer hide={hide}>
        <div className="text-align-buttons">
          <MultiOptionButton
            options={textAligns}
            onChange={(textAlign) => onChange({ textAlign })}
            value={shape.textAlign || 'left'}
            LabelComponent={CustomAlignLabel}
          />
        </div>
        <div className="icon-button-container" onClick={() => onChange({ isBold: !shape.isBold })}>
          <FontAwesomeIcon.Memo icon={shape.isBold ? faBoldSelect : faBold} className="icon-button" />
        </div>
        <div className="icon-button-container" onClick={() => onChange({ isItalic: !shape.isItalic })}>
          <FontAwesomeIcon.Memo icon={shape.isItalic ? faItalicSelect : faItalic} className="icon-button" />
        </div>
        <ToolBarColorPicker initialValue={shape.fill} onChange={(value) => onChange({ fill: value })} icon={faFont} />
        <OrderObjects />
        <div className="break" />
        <FontFamilySelect initialValue={shape.fontFamily} onChange={(value) => changeText({ fontFamily: value })} />
        <SelectFontSize value={shape.fontSize} onChange={(value) => changeText({ fontSize: value })} />
        <ShapeLock object={shape} />
        <ShapeDelete />
      </ActionsContainer>
    </>
  );
};

export default ActionBarText;
