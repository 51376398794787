// @ts-check

/**
 * @param {import("actions/scene.types").CalendarType} calendarType calendar type
 */
export const getCalendarSettings = (calendarType = 1) => {
  /** @type {{ width: number; height: number; margin: number }} */
  let DayShape = {
    width: 42,
    height: 25,
    margin: 3,
  };
  /** @type {{ row: number; column: number }} count days in row and count days in column */
  let NumberDaysInRowAndColumn = { row: 7, column: 7 };

  if (calendarType === 1) {
    DayShape = {
      width: 42,
      height: 25,
      margin: 2,
    };
    NumberDaysInRowAndColumn = { row: 7, column: 7 };
  }
  if (calendarType === 2) {
    DayShape = {
      width: 42,
      height: 35,
      margin: 3,
    };
    NumberDaysInRowAndColumn = { row: 2, column: 31 };
  }

  const DaysContainerShape = {
    width: DayShape.width * NumberDaysInRowAndColumn.column,
    height: DayShape.height * NumberDaysInRowAndColumn.row,
    margin: 4,
  };

  const CalendarShape = {
    width: DaysContainerShape.width + 2 * DaysContainerShape.margin,
    height: DaysContainerShape.height + 2 * DaysContainerShape.margin,
  };

  const calendarSettings = {
    daysOfWeek: 7,
    NumberDaysInRowAndColumn,
    DayShape,
    DaysContainerShape,
    CalendarShape,
  };
  return calendarSettings;
};
