import { createAction, handleActions } from 'redux-actions';
import { EDGE_NOWRAP } from 'constants/editor';
import { merge } from 'lodash';
import { shapeTypes, defaultScene } from 'constants/index';
import StoreSceneHelper from './StoreSceneHelper';
/**
 * @param dimensions - object. default unit is mm
 */
const config = {
  axisColor: '#c7c6c7',
  // xLabel: '16"',
  // yLabel: '12"',
  clippingMaskUrl: 'mask.svg',
  contentOffsetY: 0,
  dimensions: defaultScene.dimensions,
  edgeWrap: EDGE_NOWRAP,
};

const state = {
  sceneWidthPx: 320,
  sceneHeightPx: 320,
};

/**
 * @param completed - if true, then we skip onboarding
 * @param step - holds the current step index
 */
const defaultState = {
  objects: [],
  clipboard: null,
  config,
  state,
  pages: [
    // FIXME: need to remove this
    StoreSceneHelper.getDefaultPage(),
  ],
};

const prefix = 'SCENE_';

export const clickOnImage = createAction(`${prefix}CLICK_ON_IMAGE`);
export const addNewPageToPhotoBook = createAction(`${prefix}ADD_NEW_PAGE_TO_PHOTO_BOOK`);
export const onCompleteUploadImage = createAction(`${prefix}ON_COMPLETE_UPLOAD_IMAGE`);
export const updatePreviewImages = createAction(`${prefix}UPDATE_PREVIEW_IMAGES`);
export const uploadPhotos = createAction(`${prefix}UPLOAD_PHOTOS`);
export const onDragEndImage = createAction(`${prefix}ON_DRAG_END_IMAGE`);
export const setBackground = createAction(`${prefix}SET_BACKGROUND`);
export const setBackgroundColor = createAction(`${prefix}SET_BACKGROUND_COLOR`);
export const addText = createAction(`${prefix}ADD_TEXT`);
export const addClipart = createAction(`${prefix}ADD_CLIPART`);
export const updateIsEditing = createAction(`${prefix}UPDATE_IS_EDITING`);
export const updateFit = createAction(`${prefix}UPDATE_FIT`);
export const resetCropping = createAction(`${prefix}RESET_CROPPING`);
export const bringForward = createAction(`${prefix}BRING_FORWARD`);
export const bringToFront = createAction(`${prefix}BRING_TO_FRONT`);
export const bringToBack = createAction(`${prefix}BRING_TO_BACK`);
export const bringBackward = createAction(`${prefix}BRING_BACKWARD`);
export const updateTextEditing = createAction(`${prefix}UPDATE_TEXT_EDITING`);
export const setDesign = createAction(`${prefix}SET_DESIGN`);
export const setLayout = createAction(`${prefix}SET_LAYOUT`);
export const addDropZone = createAction(`${prefix}ADD_DROP_ZONE`);
export const unsetBackground = createAction(`${prefix}UNSET_BACKGROUND`);
export const setImage = createAction(`${prefix}SET_IMAGE`);
export const updateObjectByIndex = createAction(`${prefix}UPDATE_OBJECT_BY_INDEX`);
export const removeObject = createAction(`${prefix}REMOVE_OBJECT`);
export const removeEditedShape = createAction(`${prefix}REMOVE_EDITED_SHAPE`);
export const copyShape = createAction(`${prefix}COPY_SHAPE`);
export const pasteShape = createAction(`${prefix}PASTE_SHAPE`);
export const nextPage = createAction(`${prefix}NEXT_PAGE`);
export const previousPage = createAction(`${prefix}PREVIOUS_PAGE`);
export const sortPage = createAction(`${prefix}SORT_PAGE`);
export const clearStage = createAction(`${prefix}CLEAR_STAGE`);
export const setObjects = createAction(`${prefix}SET_OBJECTS`);
export const updateConfig = createAction(`${prefix}UPDATE_CONFIG`);
export const updateShape = createAction(`${prefix}UPDATE_SHAPE`);
export const updateLayoutArea = createAction(`${prefix}UPDATE_LAYOUT_AREA`);
export const updatePageId = createAction(`${prefix}UPDATE_PAGE_ID`);
export const updateBorderType = createAction(`${prefix}UPDATE_BORDER_TYPE`);
export const toggleBlackAndWhite = createAction(`${prefix}TOGGLE_BLACK_WHITE`);
export const changeLayoutId = createAction(`${prefix}CHANGE_LAYOUT_ID`);
export const changeLayoutGutterType = createAction(`${prefix}CHANGE_LAYOUT_GUTTER_TYPE`);
export const changeCoverOption = createAction(`${prefix}CHANGE_COVER_OPTION`);
export const removeLayouts = createAction(`${prefix}REMOVE_LAYOUTS`);
export const updateProduct = createAction(`${prefix}UPDATE_PRODUCT`);
export const updateViewAllPages = createAction(`${prefix}UPDATE_VIEW_ALL_PAGES`);
export const toggleEventActive = createAction(`${prefix}TOGGLE_EVENT_ACTIVE`);
export const toggleIsEditArea = createAction(`${prefix}TOGGLE_IS_EDIT_AREA`);
export const toggleDisableLayouts = createAction(`${prefix}TOGGLE_DISABLE_LAYOUTS`);
export const toggleDisableEventsTab = createAction(`${prefix}TOGGLE_DISABLE_EVENTS_TAB`);
export const toggleHideEventsInGrid = createAction(`${prefix}TOGGLE_HIDE_EVENTS_IN_GRID`);
export const updateState = createAction(`${prefix}UPDATE_STATE`);
export const deleteImage = createAction(`${prefix}DELETE_IMAGE`);
export const setScene = createAction(`${prefix}SET_SCENE`);
export const setFrame = createAction(`${prefix}SET_FRAME`);
export const setEdges = createAction(`${prefix}SET_EDGES`);
export const setOrientation = createAction(`${prefix}SET_ORIENTATION`);
export const toggleOrientation = createAction(`${prefix}TOGGLE_ORIENTATION`);
export const removePage = createAction(`${prefix}REMOVE_PAGE`);
export const changeQuantity = createAction(`${prefix}CHANGE_QUALITY`);
export const addTextArrToTextObjects = createAction(`${prefix}ADD_TEXT_ARR`);
export const addImagemagickToImages = createAction(`${prefix}ADD_IMAGEMAGICK_TO_IMAGES`);
export const setStartCalendar = createAction(`${prefix}SET_START_CALENDAR`);
export const initializedFromApi = createAction(`${prefix}INITIALIZED_FROM_API`);
export const setEvents = createAction(`${prefix}SET_EVENTS`);
export const setDefaultEvents = createAction(`${prefix}SET_DEFAULT_EVENTS`);
export const addEvent = createAction(`${prefix}ADD_EVENT`);
export const updateEvent = createAction(`${prefix}UPDATE_EVENT`);
export const removeEventById = createAction(`${prefix}REMOVE_EVENT_BY_ID`);

const objectDefaults = {
  x: 0,
  y: 0,
  width: 100,
  height: 100,
};

const reducer = handleActions(
  {
    [updateObjectByIndex]: (store, { payload }) => ({
      ...store,
      objects: store.objects.map((object, index) => {
        if (index === payload.index || (object && object.imageId === payload.imageId)) {
          return {
            ...object,
            ...payload.modifier,
          };
        }
        return object;
      }),
    }),
    [clearStage]: (store) => ({
      ...merge(store, { config: { isEditing: false, textEditing: false, isCrop: false } }),
      objects: [...defaultState.objects],
    }),
    [setObjects]: (store, { payload }) => ({
      ...merge(store, { config: { isEditing: false, textEditing: false, isCrop: false } }),
      objects: payload.map((o) => (o ? { ...objectDefaults, ...o } : null)),
    }),
    [updateConfig]: (store, { payload }) => {
      const newConfig = merge({}, store.config, payload);
      let newObjects = store.objects;
      if (store.config.productMode === 'designs' && newConfig.productMode === 'layout' && !newConfig.admin) {
        newObjects = [];
        newConfig.designId = '';
      }
      if (newConfig.productMode === 'layout' && newConfig.mode) {
        if (newConfig.admin) {
          newConfig.layoutId = '';
        }
      }
      return { ...store, objects: newObjects, config: newConfig };
    },
    [updateTextEditing]: (store, { payload }) => {
      // if (store.config.isEditing !== payload.isEditing)
      store.config.textEditing = payload.textEditing;
      return store;
    },
    [updateState]: (store, { payload }) => {
      const newState = merge({}, store.state, payload);
      if (
        newState.sceneContainerWidthPx < 500 &&
        newState.sceneContainerWidthPx === store.state.sceneContainerWidthPx &&
        newState.sceneContainerHeightPx < store.state.sceneContainerHeightPx
      ) {
        newState.sceneContainerHeightPx = store.state.sceneContainerHeightPx;
        newState.sceneHeightPx = store.state.sceneHeightPx;
      }
      return { ...store, state: newState };
    },
    [deleteImage]: (store, { payload }) => StoreSceneHelper.deleteImage(store, payload),
    [setFrame]: (store, { payload }) => StoreSceneHelper.setFrame(store, payload),
    [setEdges]: (store, { payload }) => StoreSceneHelper.setEdges(store, payload),
    [setOrientation]: (store, { payload }) => StoreSceneHelper.setOrientation(store, payload),
    [toggleOrientation]: (store, { payload }) => StoreSceneHelper.toggleOrientation(store, payload),
    [onCompleteUploadImage]: (store, { payload }) => StoreSceneHelper.onCompleteUploadImage(store, payload),
    [updatePreviewImages]: (store, { payload }) => StoreSceneHelper.updatePreviewImages(store, payload),
    [removePage]: (store, { payload }) => StoreSceneHelper.removePageById(store, payload),
    [changeQuantity]: (store, { payload }) => StoreSceneHelper.changeQuantity(store, payload),
    [addNewPageToPhotoBook]: (store, { payload }) => StoreSceneHelper.addNewPageToPhotoBook(store, payload),
    [clickOnImage]: (store, { payload }) => StoreSceneHelper.clickOnImage(store, payload),
    [uploadPhotos]: (store, { payload }) => StoreSceneHelper.uploadPhotos(store, payload),
    [onDragEndImage]: (store, { payload }) => StoreSceneHelper.onDragEndImage(store, payload),
    [setBackground]: (store, { payload }) => StoreSceneHelper.changeBackground(store, payload),
    [setBackgroundColor]: (store, { payload }) => StoreSceneHelper.changeBackgroundColor(store, payload),
    [addClipart]: (store, { payload }) => StoreSceneHelper.addShapeToCurrentPage(store, shapeTypes.clipart, payload),
    [addDropZone]: (store) => StoreSceneHelper.addShapeToCurrentPage(store, shapeTypes.dropZone),
    [addText]: (store) => StoreSceneHelper.addShapeToCurrentPage(store, shapeTypes.text),
    [updateViewAllPages]: (store, { payload }) => StoreSceneHelper.updateViewAllPages(store, payload),
    [setDesign]: (store, { payload }) => StoreSceneHelper.setDesign(store, payload),
    [toggleIsEditArea]: (store, { payload }) => StoreSceneHelper.toggleIsEditArea(store, payload),
    [toggleDisableLayouts]: (store, { payload }) => StoreSceneHelper.toggleDisableLayouts(store, payload),
    [toggleDisableEventsTab]: (store, { payload }) => StoreSceneHelper.toggleDisableEventsTab(store, payload),
    [toggleHideEventsInGrid]: (store, { payload }) => StoreSceneHelper.toggleHideEventsInGrid(store, payload),
    [addTextArrToTextObjects]: (store) => StoreSceneHelper.addTextArrToTextObjects(store),
    [addImagemagickToImages]: (store) => StoreSceneHelper.addImagemagickToImages(store),
    [unsetBackground]: (store) => StoreSceneHelper.unsetBackground(store),
    [setImage]: (store, { payload }) => StoreSceneHelper.setImage(store, payload),
    [removeObject]: (store, { payload }) => StoreSceneHelper.removeShapeFromCurrentPage(store, payload),
    [removeEditedShape]: (store) => StoreSceneHelper.removeEditedShapeFromCurrentPage(store),
    [removeLayouts]: (store, { payload }) => StoreSceneHelper.removeLayoutsFromCurrentPage(store, payload),
    [copyShape]: (store, { payload }) => StoreSceneHelper.copyShapeFromCurrentPage(store, payload),
    [updateLayoutArea]: (store, { payload }) => StoreSceneHelper.updateLayoutArea(store, payload),
    [nextPage]: (store, { payload }) => StoreSceneHelper.goToNextPage(store, payload),
    [previousPage]: (store, { payload }) => StoreSceneHelper.goToPreviousPage(store, payload),
    [sortPage]: (store, { payload }) => StoreSceneHelper.sortPage(store, payload),
    [pasteShape]: (store, { payload }) => StoreSceneHelper.pasteShapeFromCurrentPage(store, payload),
    [updateIsEditing]: (store, { payload }) => StoreSceneHelper.updateIsEditing(store, payload),
    [updateFit]: (store, { payload }) => StoreSceneHelper.updateFit(store, payload),
    [resetCropping]: (store, { payload }) => StoreSceneHelper.resetCropping(store, payload),
    [bringForward]: (store, { payload }) => StoreSceneHelper.bringForward(store, payload),
    [bringToFront]: (store, { payload }) => StoreSceneHelper.bringToFront(store, payload),
    [bringToBack]: (store, { payload }) => StoreSceneHelper.bringToBack(store, payload),
    [bringBackward]: (store, { payload }) => StoreSceneHelper.bringBackward(store, payload),
    [setScene]: (store, { payload }) => StoreSceneHelper.setScene(store, payload),
    [updateShape]: (store, { payload }) => StoreSceneHelper.updateShape(store, payload),
    [changeLayoutId]: (store, { payload }) => StoreSceneHelper.changeLayoutId(store, payload),
    [updatePageId]: (store, { payload }) => StoreSceneHelper.updatePageId(store, payload),
    [updateBorderType]: (store, { payload }) => StoreSceneHelper.updateBorderType(store, payload),
    [toggleBlackAndWhite]: (store, { payload }) => StoreSceneHelper.toggleBlackAndWhite(store, payload),
    [changeLayoutGutterType]: (store, { payload }) => StoreSceneHelper.changeLayoutGutterType(store, payload),
    [changeCoverOption]: (store, { payload }) => StoreSceneHelper.changeCoverOption(store, payload),
    [updateProduct]: (store, { payload }) => StoreSceneHelper.updateProduct(store, payload),
    [setStartCalendar]: (store, { payload }) => StoreSceneHelper.setStartCalendar(store, payload),
    [initializedFromApi]: (store, { payload }) => StoreSceneHelper.initializedFromApi(store, payload),
    [setEvents]: (store, { payload }) => StoreSceneHelper.setEvents(store, payload),
    [setDefaultEvents]: (store, { payload }) => StoreSceneHelper.setDefaultEvents(store, payload),
    [addEvent]: (store, { payload }) => StoreSceneHelper.addEvent(store, payload),
    [updateEvent]: (store, { payload }) => StoreSceneHelper.updateEvent(store, payload),
    [toggleEventActive]: (store, { payload }) => StoreSceneHelper.toggleEventActive(store, payload),
    [removeEventById]: (store, { payload }) => StoreSceneHelper.removeEventById(store, payload),
  },
  defaultState,
);

export default reducer;
