export const fullScreenLayout = [
  {
    id: 'default',
    label: '-1',
    default: true,
    margin: 0,
    layout: [{ height: 100, width: 100, x: 0, y: 0 }],
  },
];

export const layouts = [
  {
    id: '1',
    label: '2.1',
    margin: 10,
    layout: [
      { height: 50, width: 100, x: 0, y: 50 },
      { height: 50, width: 100, x: 0, y: 0 },
    ],
  },
  {
    id: '2',
    label: '2.2',
    margin: 10,
    layout: [
      { height: 100, width: 50, x: 0, y: 0 },
      { height: 100, width: 50, x: 50, y: 0 },
    ],
  },
  {
    id: '3',
    label: '3.1',
    margin: 10,
    layout: [
      { height: 100, width: 50, x: 0, y: 0 },
      { height: 50, width: 50, x: 50, y: 0 },
      { height: 50, width: 50, x: 50, y: 50 },
    ],
  },
  {
    id: '4',
    label: '3.2',
    margin: 10,
    layout: [
      { height: 50, width: 100, x: 0, y: 50 },
      { height: 50, width: 50, x: 0, y: 0 },
      { height: 50, width: 50, x: 50, y: 0 },
    ],
  },
  {
    id: '5',
    label: '4.1',
    default: true,
    margin: 10,
    layout: [
      { height: 50, width: 50, x: 0, y: 0 },
      { height: 50, width: 50, x: 0, y: 50 },
      { height: 50, width: 50, x: 50, y: 0 },
      { height: 50, width: 50, x: 50, y: 50 },
    ],
  },
  {
    id: '6',
    label: '5.1',
    margin: 10,
    layout: [
      { height: 60, width: 62, x: 0, y: 0 },
      { height: 40, width: 38, x: 62, y: 0 },
      { height: 20, width: 38, x: 62, y: 40 },
      { height: 40, width: 50, x: 0, y: 60 },
      { height: 40, width: 50, x: 50, y: 60 },
    ],
  },
  {
    id: '5.2',
    label: '5.2',
    margin: 10,
    layout: [
      { height: 43, width: 50, x: 0, y: 0 },
      { height: 25, width: 50, x: 0, y: 43 },
      { height: 32, width: 50, x: 0, y: 68 },
      { height: 33, width: 50, x: 50, y: 0 },
      { height: 67, width: 50, x: 50, y: 33 },
    ],
  },
  {
    id: '7',
    label: '6.1',
    margin: 10,
    layout: [
      { height: 50, width: 30, x: 0, y: 0 },
      { height: 50, width: 40, x: 30, y: 0 },
      { height: 50, width: 30, x: 70, y: 0 },
      { height: 50, width: 30, x: 0, y: 50 },
      { height: 50, width: 40, x: 30, y: 50 },
      { height: 50, width: 30, x: 70, y: 50 },
    ],
  },
  {
    id: '7.1',
    label: '7.1',
    margin: 10,
    layout: [
      { height: 30, width: 40, x: 0, y: 0 },
      { height: 30, width: 60, x: 40, y: 0 },
      { height: 45, width: 30, x: 0, y: 30 },
      { height: 35, width: 70, x: 30, y: 30 },
      { height: 25, width: 30, x: 0, y: 75 },
      { height: 35, width: 30, x: 30, y: 65 },
      { height: 35, width: 40, x: 60, y: 65 },
    ],
  },
  {
    id: '8',
    label: '8.1',
    margin: 10,
    layout: [
      { height: 290 / 6.5, width: 425 / 6.45, x: 0, y: 0 },
      { height: 215 / 6.5, width: 220 / 6.45, x: 425 / 6.45, y: 0 / 6.5 },
      { height: 165 / 6.5, width: 270 / 6.45, x: 0 / 6.45, y: 290 / 6.5 },
      { height: 165 / 6.5, width: 155 / 6.45, x: 270 / 6.45, y: 290 / 6.5 },
      { height: 240 / 6.5, width: 220 / 6.45, x: 425 / 6.45, y: 215 / 6.5 },
      { height: 195 / 6.5, width: 200 / 6.45, x: 0 / 6.45, y: 455 / 6.5 },
      { height: 195 / 6.5, width: 145 / 6.45, x: 200 / 6.45, y: 455 / 6.5 },
      { height: 195 / 6.5, width: 300 / 6.45, x: 345 / 6.45, y: 455 / 6.5 },
    ],
  },
  {
    id: '9',
    label: '9.1',
    margin: 10,
    layout: [
      { height: 100 / 3, width: 100 / 3, x: 0, y: 0 },
      { height: 100 / 3, width: 100 / 3, x: 0, y: 100 / 3 },
      { height: 100 / 3, width: 100 / 3, x: 0, y: 200 / 3 },
      { height: 100 / 3, width: 100 / 3, x: 100 / 3, y: 0 },
      { height: 100 / 3, width: 100 / 3, x: 100 / 3, y: 100 / 3 },
      { height: 100 / 3, width: 100 / 3, x: 100 / 3, y: 200 / 3 },
      { height: 100 / 3, width: 100 / 3, x: 200 / 3, y: 0 },
      { height: 100 / 3, width: 100 / 3, x: 200 / 3, y: 100 / 3 },
      { height: 100 / 3, width: 100 / 3, x: 200 / 3, y: 200 / 3 },
    ],
  },
];
