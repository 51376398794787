import React from 'react';
import { isArray } from 'lodash';
import { useMode, usePageIds } from 'hooks';
import { PageConfigContextProvider } from 'context/pageConfig';
import AddNewPhoto from 'components/editors/PhotoPrint/components/AddNewPhoto/AddNewPhoto';
import { modes } from 'constants/index';
import WarningLowQualityImage from '../WarningLowQualityImage';
import WarningEmptyImage from '../WarningEmptyImage';
import WarningPageWithNoImages from '../WarningPageWithNoImages';
import EditorPage from './components/EditorPage/EditorPage';
import PhotoActions from './components/PhotoActions';
import EditIconComponent from './components/EditIconComponent/EditIconComponent';
import c from './AllPages.module.scss';

const AllPage = () => {
  const mode = useMode();
  const pageIds = usePageIds();
  return (
    <div className={c.container}>
      <div className={c.inner}>
        {mode === modes.calendar ? <div className={c.pageDescription}>Carefully check your calendar below</div> : null}
        <WarningLowQualityImage />
        <WarningEmptyImage />
        <WarningPageWithNoImages />
        <div className={c.pages}>
          {pageIds?.map((pageId, pageIndex) => {
            if (isArray(pageId)) {
              return (
                <div className={c.photoBookContainer}>
                  {pageId?.map((pageId, pageIndex) => (
                    <PageConfigContextProvider
                      key={pageId}
                      value={{ pageId, pageIndex, isLastImage: pageIndex === pageIds.length - 1 }}
                    >
                      <EditorPage />
                    </PageConfigContextProvider>
                  ))}
                </div>
              );
            }
            return (
              <div className={c.pageContainer}>
                <PageConfigContextProvider
                  key={pageId}
                  value={{ pageId, pageIndex, isLastImage: pageIndex === pageIds.length - 1 }}
                >
                  <EditorPage />
                  {mode === modes.calendar ? <EditIconComponent /> : null}
                  {mode === modes.photoPrint || mode === modes.photoBook ? <PhotoActions /> : null}
                </PageConfigContextProvider>
              </div>
            );
          })}
          {mode === modes.photoPrint ? <AddNewPhoto width={100} height={100} className={c.pageContainer} show /> : null}
        </div>
      </div>
    </div>
  );
};

export default AllPage;
